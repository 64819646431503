<template>
  <template v-if="footerLabels?.length">
    <component :is="FooterLabelTemplate" />
  </template>
</template>

<script setup>
const storeConfig = useStoreConfig()
const { themeComponents, footerLabels } = storeToRefs(storeConfig)

const FooterLabelTemplate = shallowRef();
switch(unref(themeComponents)?.footer_label_component) {
  case '1':
    FooterLabelTemplate.value = resolveComponent('LazyTemplatesFooterLabels1')
    break;
  case '2':
    FooterLabelTemplate.value = resolveComponent('LazyTemplatesFooterLabels2')
    break;
  case '3':
    FooterLabelTemplate.value = resolveComponent('LazyTemplatesFooterLabels3')
    break;
  default :
    FooterLabelTemplate.value = null;
}
</script>

<style scoped>

</style>